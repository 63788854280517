import React from "react";
import { Link } from "gatsby";

function Footer() {
  return (
    <footer className="bg-blue-800">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link to="/impressum">
              <p className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Impressum
              </p>
            </Link>
          </div>
          <div className="px-5 py-2">
          <Link to="/privacy-policy">
              <p className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Datenschutz
              </p>
            </Link>
          </div>
        </nav>
        <div className="mt-8">
          <p className="text-center text-base leading-6 text-gray-400">
            Copyright &copy; 2020 - Lukas Euler - SEO-Experte aus Hamburg 🥇
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
