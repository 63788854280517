import { Link, graphql, useStaticQuery } from "gatsby";
//import React, { useState } from "react";
import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Img from "gatsby-image";

function Header() {
  //const [isExpanded, toggleExpansion] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "logo_new2.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 143, height: 42) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  const imageData = data.desktop.childImageSharp.fixed;

  return (
    <header className="bg-le-grey shadow-lg sticky top-0 z-50">
      <nav className="shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* <!-- Mobile menu button --> 
                <button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  aria-label="Main menu"
                  aria-expanded="false"
                  onClick={() => toggleExpansion(!isExpanded)}
                >
                  {/* <!-- Icon when menu is closed. --> 
                  <svg
                    className="block h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* <!-- Icon when menu is open. --> 
                  <svg
                    className="hidden h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button> */}
              </div>
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <h1 className="block lg:hidden w-auto h-10">
                    <Img
                      className="mr-2"
                      fixed={imageData}
                      loading="eager"
                      fadeIn={false}
                    />
                  </h1>
                </Link>
                <Link to="/">
                  <h1 className="hidden lg:block w-auto h-10">
                    <Img
                      className="mr-2"
                      fixed={imageData}
                      loading="eager"
                      fadeIn={false}
                    />
                  </h1>
                </Link>
              </div>
              {/* <div className="hidden md:ml-6 md:flex">
                <Link
                  to="/"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-orange-300 focus:outline-none focus:text-gray-700 focus:border-orange-700 transition duration-150 ease-in-out"
                  activeClassName="border-orange-500 text-gray-900"
                >
                  Home
                </Link>
                <Link
                  to="/wissen"
                  className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-orange-300 focus:outline-none focus:text-gray-700 focus:border-orange-700 transition duration-150 ease-in-out"
                  activeClassName="border-orange-500 text-gray-900"
                >
                  SEO-Wissen
                </Link>
                <Link
                  to="#"
                  className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-orange-300 focus:outline-none focus:text-gray-700 focus:border-orange-700 transition duration-150 ease-in-out"
                  activeClassName="border-orange-500 text-gray-900"
                >
                  Blog
                </Link>
              </div> */}
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AnchorLink to="/#contactform" title="Our team">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
                    </svg>
                    <span>Kontakt aufnehmen</span>
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

        {/*   <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  --> */}
        {/* <div className={`${isExpanded ? `block` : `hidden`}`}>
          <div className="pt-2 pb-3">
            <Link
              to="/"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6"
              activeClassName="border-orange-500 text-gray-700 bg-orange-50 focus:bg-orange-100 focus:border-orange-700"
            >
              Home
            </Link>
            <Link
              to="/wissen"
              className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6"
              activeClassName="border-orange-500 text-gray-700 bg-orange-50 focus:bg-orange-100 focus:border-orange-700"
            >
              SEO-Wissen
            </Link>
            <Link
              to="#"
              className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6"
              activeClassName="border-orange-500 text-gray-700 bg-orange-50 focus:bg-orange-100 focus:border-orange-700"
            >
              Blog
            </Link>
          </div>
        </div> */}
      </nav>
    </header>
  );
}

export default Header;
