import PropTypes from "prop-types";
import React from "react";

import Header from "./sections/header";
import Footer from "./sections/footer";
//import logoFooter from "../images/logo_footer-768x225.jpg";

function Layout({ children }) {
  return (
    <div className="container flex flex-col min-h-screen font-sans text-gray-900">
      <Header />
      <main className="bg-le-grey flex-1 w-full shadow-lg">{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
